import React from "react";

function ScrollButton(props)
{
    return (
        <>
            <button className="scroll-button fade-in" onClick={props.onClick}>
                <p><i class="arrow down"></i></p>
                <h3>Projects</h3>
            </button>

        </>
        );
}
export default ScrollButton;