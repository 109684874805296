import LinkButton from "./LinkButton";

const ProjectCard = ({title, description, content, tags, buttons, thumbnail}) => {
    return (
        <div className="project-card">
            <div className={"card-content-container"}>
                <h2>{title}</h2>
                <p>{description}</p>
                {content}
                <div className="tags">
                    {tags && tags.map((tag, index) => (
                        <span className="cardTag" key={index}>{tag}</span>
                    ))}
                </div>
            </div>
            <div className="card-thumbnail-container">
                {thumbnail && <img src={thumbnail} alt={""} className={"card-thumbnail"}></img>}
            </div>
            <div className="buttons">
                {
                    // <button className="link-button" style={{backgroundColor: "#415972"}}>See more</button>
                }
                {buttons}
            </div>
        </div>
    );
};

export default ProjectCard;

export function TarotProjectCard() {
    return <ProjectCard title={"TAROT: Arcane Ascension"}
                        description={"A Hack'n'Slash action roguelite."}
                        tags={["C#", "Unity", "UI/UX", "Demo"]}
                        thumbnail={"img/ProjectThumbs/Tarot.png"}
                        content={
                            <div>
                                <h4>What I worked on:</h4>
                                <li>Player controller</li>
                                <li>Combat</li>
                                <li>Card ability system</li>
                                <li>Snake boss</li>
                                <li>UI programming</li>
                                <li>General gameplay programming</li>
                            </div>
                        }
                        buttons={
                            <>
                                <LinkButton href="https://big-wheel-games.itch.io/tarot" text={"Itch.io"}/>
                                <LinkButton href="https://tarot.bigwheel.tech" text={"Website"}/>
                            </>
                        }
    />
}

export function Karts() {
    return <ProjectCard title={"Karts"}
                        description={"A chaotic local multiplayer kart racing game."}
                        tags={["C#", "Unity", "AI"]}
                        content={
                            <div>
                                <h4>What I worked on:</h4>
                                <li>Racer AI</li>
                                <li>Spline based track mesh generation</li>
                            </div>
                        }
                        thumbnail={"img/ProjectThumbs/Karts.png"}
    />
}

export function PirateVR() {
    return <ProjectCard title={"Pirate VR"}
                        description={"Physics based melee combat in VR."}
                        tags={["C#", "Unity", "AI", "Physics"]}
                        content={
                            <div>
                                <h4>What I worked on:</h4>
                                <li>Melee combat</li>
                                <li>Active ragdolls</li>
                                <li>Enemy AI</li>
                            </div>
                        }
                        thumbnail={"img/ProjectThumbs/PirateVR.gif"}
    />
}

export function DynamicMusicSystem() {
    return <ProjectCard
        title={"Dynamic music system"}
        description={"A simple dynamic music system plugin for Unity"}
        thumbnail={"img/ProjectThumbs/DynamicMusic.png"}
        tags={["C#", "Unity", "Audio"]}
    />
}

export function TextEventPlugin() {
    return <ProjectCard
        title={"Text Event Toolkit"}
        description={"A Unity plugin to create Roguelike style text events, developed for TAROT: Arcane Ascension. Includes a GUI to design the text events and supports calling abatrairy methods upon result selection via" +
            " reflection."}
        tags={["C#", "Unity"]}
        buttons=
            {
                <LinkButton href="https://github.com/WoofWoofGrr/text-event-toolkit" text={"Source code"}/>
            }
        thumbnail={"img/ProjectThumbs/TET.png"}
    />
}

export function AStar() {
    return <ProjectCard
        title={"A* Pathfinding demo"}
        description={"A simple A* pathfinding demo in C++ using SFML."}
        tags={["C++", "SFML", "AI", "Demo"]}
        buttons=
            {
                <LinkButton href="" text={"Download"}/>
            }
        thumbnail={"img/ProjectThumbs/AStar.png"}
    />
}

export function Mothership() {
    return <ProjectCard
        title={"Mothership"}
        description={"Arcade mobile game, control an alien spacecraft and use the tractor beam to collect objects."}
        tags={["C#", "Unity", "Game", "UI/UX"]}
        buttons=
            {
                <LinkButton href="https://play.google.com/store/apps/details?id=com.devonmillar.mothership"
                            text={"Google Play"}/>
            }
        thumbnail={"https://play-lh.googleusercontent.com/VLqHem0f6ic9Id75b0Ovz_oEu_UPQ_iyuX6YYVzRdvjiN2JUXWuflFmvONmLrFYCP1o=w2560-h1440-rw"}
    />
}

export function SalmonRun() {
    return <ProjectCard
        title={"Salmon Run"}
        description={"A relaxing 2D top down game about a salmon's journey to spawn."}
        content={
            <div>
                <h4>What I worked on:</h4>
                <li>Fish swarm AI</li>
                <li>Player control</li>
                <li>Audio programming</li>
                <li>Sounds</li>
                <li>Music</li>
            </div>
        }
        tags={["C#", "Unity", "AI", "Game", "FMOD", "Audio"]}
        thumbnail={"https://img.itch.zone/aW1hZ2UvMTY0OTgxMC85NzIxNDg4LnBuZw==/original/yUVQ93.png"}
        buttons=
            {
                <LinkButton href="https://the-reel-deal.itch.io/salmon-run" text={"Itch.io"}/>
            }
    />
}

export function SpellsDisaster() {
    return <ProjectCard
        title={"Spells Disaster"}
        description={"A side scrolling physics based shooter set in a comic book."}
        content={
            <div>
                <h4>What I worked on:</h4>
                <li>Player controller</li>
                <li>Enemy physics</li>
            </div>
        }
        tags={["C#", "Unity", "Game"]}
        buttons=
            {
                <LinkButton href="https://the-reel-deal.itch.io/spells-disaster" text={"Itch.io"}/>
            }
        thumbnail={"https://img.itch.zone/aW1hZ2UvMTY1MjQxMi85NzIyMDgwLnBuZw==/original/T9ikHA.png"}
    />
}

export function TopDownShooterAI() {
    return <ProjectCard
        title={"Top down shooter AI demo"}
        description={"An advanced top down shooter AI built in Unity. The agent uses 2 independent state machines control movement and attack actions to fight the player. The agent has a sense of self preservation and will seek healing when needed. " +
            "It is capable of predicting the player's movement and will attempt to lead its shots to account for bullet travel time. If the agent loses sight of the player it will extrapolate the player's position and attempt to shoot them through the wall."}
        tags={["C#", "Unity", "AI"]}
    />
}
