import React, { useState, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { motion, AnimatePresence } from 'framer-motion';

function TopBar(props) {
    return(
        <motion.div className="top-bar"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}>
            
        <TopBarContent sectionName = {props.sectionName} />
    </motion.div>
    );
}


function TopBarContent(props) {
    return (
        <div className='side-bar'>
            <h3 className='anchored-title'>Devon Millar // {props.sectionName}</h3>
        </div>
    );
}

export default TopBar;



