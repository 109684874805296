import {Waypoint} from 'react-waypoint';
import ProfileLink from './ProfileLink';
import {useState} from 'react';
import ScrollButton from "./ScrollButton";

var currentPosition = 0;

function Landing(props) {

    const [isVisible, setIsVisible] = useState(false);

    if (isVisible) {
        props.setCurrentSection("Landing")
    }

    const ScrollToProjects = () =>
    {
        var elmntToView = document.getElementById("projects-section");
        elmntToView.scrollIntoView({ behavior: "smooth"});
    }
    return (
        <Waypoint topOffset="50%"
                  onEnter={() => setIsVisible(true)} onLeave={() => setIsVisible(false)}>
            <section id="landing-section" className='snap-child'>
                <header className="fall-down">
                    <h1>Devon Millar</h1>
                </header>
                <header>
                    <span className="typing-animation subtitle">Game programmer<span className="cursor"/></span>
                </header>

                <nav className="fade-in-late">
                    <ul>
                        <ProfileLink href="https://github.com/WoofWoofGrr" imageSrc="img/github-logo.png"
                                     imageAlt="GitHub"/>
                        <ProfileLink href="https://devon-millar.itch.io/" imageSrc="img/itchio-logo.svg"
                                     imageAlt="Itch.io"/>
                    </ul>
                </nav>
                <section className="content fade-in-late">
                    <p className="email">mail@devonmillar.com</p>
                </section>
                <ScrollButton onClick = {ScrollToProjects} />
            </section>
        </Waypoint>
    );
}

export default Landing;
