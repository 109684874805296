import React from "react";

function LinkButton(props)
{
    return (
        <a href={props.href} className="link-button" target="_blank" rel="noopener noreferrer">
            {props.text}
        </a>
    );
}
export default LinkButton;