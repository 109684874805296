import logo from './logo.svg';
import './App.css';
import ProfileLink from './components/ProfileLink';
import React, {useState, useEffect} from 'react';
import LandingPage from './components/LandingPage';
import ProjectsPage from './components/ProjectsPage';
import {AnimatePresence} from 'framer-motion';
import TopBar from "./components/TopBar";
import ScrollButton from "./components/ScrollButton";

var currentPosition = 0;

function App() {

    const [currentSection, setCurrentSection] = useState("");

    return (
        <div className="snap-container">
            <AnimatePresence>
                {currentSection !== "Landing" &&
                    <TopBar sectionName={currentSection}/>
                }
            </AnimatePresence>
            <LandingPage setCurrentSection={setCurrentSection}/>
            <ProjectsPage setCurrentSection={setCurrentSection}/>
        </div>
    );
}

export default App;
