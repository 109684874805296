import React from "react";

function ProfileLink(props)
{
    return (
        <li className="profile-link">
        <a href={props.href}>
          <img src={props.imageSrc} alt={props.imageAlt} />
        </a>
      </li>
    );
}
export default ProfileLink;