import React, {useState, useEffect} from 'react';
import {Waypoint} from 'react-waypoint';
import {motion, AnimatePresence} from 'framer-motion';
import ProjectCard, {
    AStar,
    Karts,
    Mothership, PirateVR,
    SalmonRun,
    SpellsDisaster,
    TextEventPlugin,
    TopDownShooterAI
} from './ProjectCard';
import {TarotProjectCard} from './ProjectCard';
import {DynamicMusicSystem} from './ProjectCard';

function ProjectsPage(props) {

    const [isVisible, setIsVisible] = useState(false);
    const [popupActive, setPopupActive] = useState(true);

    var popup = isVisible && popupActive && (<div className='centered-banner fade-in-out'
                                   onAnimationEnd={() => {
                                       setPopupActive(false)
                                   }}
                                   style={{
                                       position: 'fixed',
                                       top: '50%',
                                       left: '50%',
                                       transform: 'translate(-50%, -50%)',
                                       textAlign: 'center',
                                   }}>

        <h1>// Projects //</h1>
    </div>)

    const content =
        <div>
            <motion.div id='project-card-root'
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.5}}>
                <h3>// 2023</h3>
                <TarotProjectCard/>
                <DynamicMusicSystem/>
                <TextEventPlugin/>
                <h3>// 2022</h3>
                <SalmonRun/>
                <Mothership/>
                <PirateVR/>
                <Karts/>
                <SpellsDisaster/>
                {
                //<h3>// 2021</h3>
                 //   <TopDownShooterAI/>
                 //   <AStar/>
                    
                }
            </motion.div>
        </div>

    if (isVisible) {
        props.setCurrentSection("Projects")
    }

  const Close = () => {
    setIsVisible(false)
    setPopupActive(true)
  }
    return (
        <Waypoint bottomOffset="60%"
                  onEnter={() => setIsVisible(true)} onLeave={() => Close()}>
            <section id="projects-section" className='snap-child'>
                <AnimatePresence>
                    {isVisible && content}
                </AnimatePresence>
                {popup}

            </section>

        </Waypoint>
    );
}

export default ProjectsPage;
